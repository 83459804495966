/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import arbitrationCourtDocuments from '../../../constants/arbitrationCourtDocuments';
import Button from '../../ReusableElements/Buttons/Button/Button';
import CallOrderForm from '../../CallOrderForm/CallOrderForm';
import './ArbitrationCourt.scss';

export default function ArbitrationCourt() {
  const { t, i18n } = useTranslation(['service_pages', 'buttons']);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Івано-Франківська торгово-промислова палата',
    url: 'https://cci.if.ua/',
    logo: 'https://cci.if.ua/static/media/logo_small.088e05e0446873d0c8dfb27546b82194.svg',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+380342523347',
      contactType: 'customer service',
      areaServed: 'UA',
      availableLanguage: 'Ukrainian',
    },
    sameAs: [
      'https://www.facebook.com/cci.if.ua/',
      'https://www.instagram.com/if.cci.ua/',
    ],
  };
  return (
    <section className="ArbitrationCourt">
      <Helmet>
        <title>
          Повний перелік послуг третейського суду ТПП Івано-Франківська
        </title>
        <meta
          name="description"
          content="Ознайомтеся з усіма доступними послугами третейського суду
          ТПП Івано-Франківська та оберіть ті, які відповідають Вашим потребам."
        />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <div className="wrapper">
        <div className="arbitration-court-content">
          <div className="arbitration-court-content-top-row">
            <a
              className="previous-page-link"
              href={
                i18n.languages[0] === 'en'
                  ? '/en/'
                  : '/'
              }
            >
              {t('previous_page_title')}
            </a>
            <div className="arbitration-court-content-top-row__right-side">
              <h1 className="heading-one">
                {t('heading.arbitration_court')}
              </h1>
              <p className="description">
                {t('description')}
              </p>
            </div>
          </div>
          <div className="arbitration-court-document-list">
            {
              arbitrationCourtDocuments.map((arbitrationCourtDocument) => (
                <div
                  key={uuidv4()}
                  className="arbitration-court-document-list__item"
                >
                  <p className="arbitration-court-document-list__title">
                    {t(`arbitration_court_documents.${arbitrationCourtDocument.titleKey}`)}
                  </p>
                  <div className="buttons">
                    {/* TODO - remove hardcoded link */}
                    <Button
                      priority="primary"
                      link={`https://cci.if.ua/downloads/${t(`arbitration_court_documents.${arbitrationCourtDocument.titleKey}`)}.PDF`}
                      download
                    >
                      {t('buttons:download_the_form')}
                    </Button>
                    {/* <Button
                      priority="secondary"
                      link="#"
                      target="_blank"
                    >
                      {t('buttons:view_the_sample')}
                    </Button> */}
                  </div>
                </div>
              ))
            }
          </div>
          <h2 className="heading-two heading-two_form">
            {t('call_order_form_heading')}
          </h2>
          <p className="description">
            {t('call_order_form_description')}
          </p>
          <CallOrderForm />
        </div>
      </div>
    </section>
  );
}
